import styled from "@emotion/styled";
import { MOBILE_QUERY_MIN_WIDTH } from "constants/vp-sizes";
export const StyledMobileTabs = styled.div`
  *:focus {
    outline: none !important;
  }

  .carousel {
    .slick-slider.focus {
      .slick-list {
        .slick-track {
          padding: 0;
        }
      }
    }
  }

  .mobile-tabs-navigation {
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    display: flex;
    gap: 8px;
    padding-left: 16px;

    .tab-nav-button-wrapper {
      display: flex;
      white-space: nowrap;
    }

    &::after {
      content: "";
      padding-right: 16px;
    }
  }

  @media (min-width: ${MOBILE_QUERY_MIN_WIDTH}) {
    .carousel {
      .slick-slide {
        .side-by-side-text-and-asset {
          .side-by-side-content {
            .full-paragraph-component-wrapper {
              padding: 0;
            }
          }
        }
      }
    }
  }
`;
