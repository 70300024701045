import styled from "@emotion/styled";
import { BACKGROUND_GRAY_COLOR_NAME, BRAND_BLACK_TEXT_COLOR, BRAND_PRIMARY_GREEN, CRM_DARK_TINT_01_COLOR_NAME, CRM_DARKER_TINT_02_COLOR_NAME, DARK_CRM_GREEN_COLOR_NAME, DARK_DEV_GREEN_COLOR_NAME, DARK_MARKETING_RED_COLOR_NAME, DARK_PROJECTS_ORANGE_COLOR_NAME, DARK_WORK_OS_IRIS_COLOR_NAME, DEV_LIGHTER_TINT_01_COLOR_NAME, SPACE_BLUE_COLOR_NAME, WHITE_COLOR_NAME } from "styles/color-consts";
import colors from "styles/colors";
export const StyledMobileTabNavButton = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  padding: 7px 16px 9px;
  border-radius: 20px;
  background-color: ${colors[BACKGROUND_GRAY_COLOR_NAME]};
  color: ${colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
  white-space: nowrap;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  &.selected {
    background-color: ${colors[SPACE_BLUE_COLOR_NAME]};
    color: ${colors[WHITE_COLOR_NAME]};
  }

  &.background-gray {
    background-color: ${colors[WHITE_COLOR_NAME]};

    &.selected {
      background-color: ${colors[SPACE_BLUE_COLOR_NAME]};
    }
  }

  &.crm-green {
    background-color: ${colors[WHITE_COLOR_NAME]};
    color: ${colors[DARK_CRM_GREEN_COLOR_NAME]};

    &.selected {
      background-color: ${colors[DARK_CRM_GREEN_COLOR_NAME]};
      color: ${colors[WHITE_COLOR_NAME]};
    }
  }

  &.marketing-red {
    background-color: ${colors[WHITE_COLOR_NAME]};
    color: ${colors[DARK_MARKETING_RED_COLOR_NAME]};

    &.selected {
      background-color: ${colors[DARK_MARKETING_RED_COLOR_NAME]};
      color: ${colors[WHITE_COLOR_NAME]};
    }
  }

  &.projects-orange {
    background-color: ${colors[WHITE_COLOR_NAME]};
    color: ${colors[DARK_PROJECTS_ORANGE_COLOR_NAME]};

    &.selected {
      background-color: ${colors[DARK_PROJECTS_ORANGE_COLOR_NAME]};
      color: ${colors[WHITE_COLOR_NAME]};
    }
  }

  &.dev-green {
    background-color: ${colors[WHITE_COLOR_NAME]};
    color: ${colors[DARK_DEV_GREEN_COLOR_NAME]};

    &.selected {
      background-color: ${colors[DARK_DEV_GREEN_COLOR_NAME]};
      color: ${colors[WHITE_COLOR_NAME]};
    }
  }

  &.white {
    background-color: transparent;
    border: 1px solid ${colors[WHITE_COLOR_NAME]};
    color: ${colors[WHITE_COLOR_NAME]};

    &.selected {
      background-color: rgba(255, 255, 255, 0.2);
      border-color: rgba(255, 255, 255, 0.2);
      color: ${colors[WHITE_COLOR_NAME]};
    }
  }

  &.dev-lighter-tint-01 {
    background-color: transparent;
    border: 1px solid ${colors[BRAND_PRIMARY_GREEN]};
    color: ${colors[BRAND_BLACK_TEXT_COLOR]};

    &.selected {
      background-color: ${colors[DEV_LIGHTER_TINT_01_COLOR_NAME]};
      color: ${colors[BRAND_BLACK_TEXT_COLOR]};
      border-color: ${colors[DEV_LIGHTER_TINT_01_COLOR_NAME]};
    }
  }

  &.crm-dark-tint-01 {
    background-color: transparent;
    border: 1px solid ${colors[CRM_DARK_TINT_01_COLOR_NAME]};
    color: ${colors[BRAND_BLACK_TEXT_COLOR]};

    &.selected {
      background-color: ${colors[CRM_DARK_TINT_01_COLOR_NAME]};
      color: ${colors[WHITE_COLOR_NAME]};
      border-color: ${colors[CRM_DARK_TINT_01_COLOR_NAME]};
    }
  }

  &.crm-darker-tint-02 {
    background-color: transparent;
    border: 1px solid ${colors[CRM_DARKER_TINT_02_COLOR_NAME]};
    color: ${colors[BRAND_BLACK_TEXT_COLOR]};

    &.selected {
      background-color: ${colors[CRM_DARKER_TINT_02_COLOR_NAME]};
      color: ${colors[WHITE_COLOR_NAME]};
      border-color: ${colors[CRM_DARKER_TINT_02_COLOR_NAME]};
    }
  }
`;
