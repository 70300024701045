import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";
export const StyledBuildingBlocksTabContentComponent = styled.div`
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  // keep 1st child relative to determine the component size
  &:not(:first-child) {
    position: absolute;
    top: 0;
    left: 0;
  }
  &.selected {
    visibility: visible;
    opacity: 1;
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .side-by-side-text-and-asset {
      .side-by-side-content.is-swap {
        flex-direction: column-reverse;
      }
    }
  }
`;
