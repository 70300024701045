import styled from "@emotion/styled";
export const StyledBuildingBlocksTabsMobileComponent = styled.div`
  .secondary-button {
    .secondary-button-text {
      font-size: 0.8125rem;
    }
    .arrow-wrapper {
      svg {
        height: 12px;
        width: 10px;
      }
    }
  }
`;
